<template>
  <div class="page-user-list">
    <div class="search-wrap" v-show="false">
      <el-form :inline="true" :model="formInline" class="demo-form-inline">
        <el-form-item label="用户名称">
          <el-input v-model="formInline.user" placeholder="审批人"></el-input>
        </el-form-item>
        <el-form-item label="性别">
          <el-select v-model="formInline.region" placeholder="请选择性别" clearable>
            <el-option label="男" value="0"></el-option>
            <el-option label="女" value="1"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="onSubmit">查询</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div class="table-wrap">
      <el-table
        :data="tableData"
        border
        v-loading="loading"
        style="width: 100%">
        <el-table-column
          fixed
          prop="companyName"
          label="公司名称"
          width="150">
        </el-table-column>
        <el-table-column
          prop="contact"
          label="联系人"
          width="120">
        </el-table-column>
        <el-table-column
          prop="contactNumber"
          label="联系电话"
          width="120">
        </el-table-column>
        <el-table-column
          prop="mail"
          label="邮箱"
          width="150" show-overflow-tooltip>
        </el-table-column>
        <el-table-column
          prop="province"
          label="省份"
          width="120">
        </el-table-column>
        <el-table-column
          prop="city"
          label="市区"
          width="120">
        </el-table-column>
        <el-table-column
          prop="address"
          label="地址"
          width="300">
        </el-table-column>
        <el-table-column
          fixed="right"
          label="操作"
          width="100">
          <template slot-scope="scope">
            <el-button @click="handleClick(scope.row)" type="text" size="small">查看</el-button>
            <el-button type="text" size="small">编辑</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="table-pagination">
      <el-pagination background layout="prev, pager, next" :total="total"></el-pagination>
    </div>
  </div>
</template>

<script>
export default {
  name: 'userList',
  methods: {
    handleClick (row) {
      console.log(row)
    },
    getList () {
      this.loading = true
      this.API.getUserinfo({}).then(({ code, data }) => {
        this.tableData = data.list
        this.total = data.total
      }).finally(() => {
        this.loading = false
      })
    },
    onSubmit () {
      console.log('submit!')
    }
  },
  data () {
    return {
      formInline: {
        user: '',
        region: ''
      },
      tableData: [],
      total: 0,
      loading: true
    }
  },
  created () {
    this.getList()
  }
}
</script>

<style scoped lang="scss">
.page-user-list{
  .search-wrap{
    margin: 20px 20px 0;
    text-align: left;
  }
  .table-wrap{
    margin: 20px 20px 20px;
  }
}
</style>
